import './App.css';
import './style/desktop.css';
import './style/mobile.css';
import Banner from './components/banner/banner';
import Footer from './components/footer/footer';
import HomePage from './pages/home';
import { Routes, Route } from 'react-router-dom';
import { createContext, useState, useEffect, useRef } from 'react';
import LoginPage from './pages/login';
import { API_ROUTES, wsUrl } from './data/constants';
import LoggedIn from './components/loggedin/loggedincomponent';
import LogsPage from './pages/logs';
import LogDetailsPage from './pages/logdetails';
import NotFoundPage from './pages/notfound';
import ProjectsPage from './pages/projects';
import ChartsPage from './pages/charts';
export const AuthContext = createContext();

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [apacheLogs, setApacheLogs] = useState([]);
  const [apacheErrorLogs, setApacheErrorLogs] = useState([]);
  const [pm2Logs, setPm2Logs] = useState([]);
  const [pm2ErrorLogs, setPm2ErrorLogs] = useState([]);
  const [projects, setProjects] = useState([]);
  const ws = useRef(null);
  const token = localStorage.getItem('token');

  useEffect(() => {
    // Initialize WebSocket only once
    ws.current = new WebSocket(wsUrl);

    ws.current.addEventListener('message', (event) => {
      const data = JSON.parse(event.data);

      if (data.type === 'UPDATED_LOGS') {
        console.log('Updated Logs:', data.updatedLogs);

        if (Notification.permission === 'granted') {
          const notification = new Notification('New Logs Updated', {
            body: 'You have new logs updated. Click to view.',
          });
          notification.onclick = function (event) {
            event.preventDefault(); // Prevent default action on click
            window.focus();
          };
        }
      }
    });

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, []);

  useEffect(() => {
    LoggedIn({ setIsLoggedIn });
    if (isLoggedIn && Notification.permission === 'default') {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          console.log('Notification permission granted.');
        } else {
          console.log('Unable to get permission to notify.');
        }
      });
    }
    async function FetchData(token) {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      try {
        const projectsResponse = await fetch(API_ROUTES.PROJECTS, {
          headers,
        });
        if (projectsResponse.ok) {
          const projectsData = await projectsResponse.json();
          setProjects(projectsData);
        } else {
          console.log('Projects error:', await projectsResponse.text());
        }

        const apacheLogsResponse = await fetch(API_ROUTES.GET_APACHELOGS, {
          headers,
        });
        if (apacheLogsResponse.ok) {
          const apacheLogsData = await apacheLogsResponse.json();
          setApacheLogs(apacheLogsData);
        } else {
          console.log('ApacheLogs error:', await apacheLogsResponse.text());
        }

        const apacheErrorLogsResponse = await fetch(
          API_ROUTES.GET_APACHEERRORLOGS,
          { headers }
        );
        if (apacheErrorLogsResponse.ok) {
          const apacheErrorLogsData = await apacheErrorLogsResponse.json();
          setApacheErrorLogs(apacheErrorLogsData);
        } else {
          console.log(
            'ApacheErrorLogs error:',
            await apacheErrorLogsResponse.text()
          );
        }

        const pm2LogsResponse = await fetch(API_ROUTES.GET_PM2LOGS, {
          headers,
        });
        if (pm2LogsResponse.ok) {
          const pm2LogsData = await pm2LogsResponse.json();
          setPm2Logs(pm2LogsData);
        } else {
          console.log('Pm2Logs error:', await pm2LogsResponse.text());
        }

        const pm2ErrorLogsResponse = await fetch(API_ROUTES.GET_PM2ERRORLOGS, {
          headers,
        });
        if (pm2ErrorLogsResponse.ok) {
          const pm2ErrorLogsData = await pm2ErrorLogsResponse.json();
          setPm2ErrorLogs(pm2ErrorLogsData);
        } else {
          console.log('Pm2ErrorLogs error:', await pm2ErrorLogsResponse.text());
        }
      } catch (error) {
        console.log('FetchData error:', error);
      }
    }

    if (!token) {
      console.log('Non connecté');
    } else {
      FetchData(token).catch((error) =>
        console.log('FetchData outer error:', error)
      );
    }
  }, [token, isLoggedIn, setIsLoggedIn]);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        apacheLogs,
        apacheErrorLogs,
        pm2Logs,
        pm2ErrorLogs,
        projects,
        setProjects,
      }}
    >
      <div className="App">
        <Banner />
        <Routes>
          <Route
            path="/"
            element={<HomePage />}
          />
          <Route
            path="/login"
            element={<LoginPage setIsLoggedIn={setIsLoggedIn} />}
          />
          <Route
            path="/logs"
            element={<LogsPage />}
          />
          <Route
            path="/charts"
            element={<ChartsPage />}
          />
          <Route
            path="/logs/:logType/:logId"
            element={<LogDetailsPage />}
          />
          <Route
            path="/projects"
            element={<ProjectsPage />}
          />
          <Route
            path="/*"
            element={<NotFoundPage />}
          />
        </Routes>
        <Footer />
      </div>
    </AuthContext.Provider>
  );
}

export default App;
