import { API_ROUTES } from '../../data/constants';

export default async function LoggedIn({ setIsLoggedIn }) {
  try {
    const token = localStorage.getItem('token');
    console.log(token);
    const formData = { token: token };

    const validToken = await fetch(`${API_ROUTES.IDCHECK}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(formData),
    });

    if (!validToken.ok) {
      console.log(`Fetch failed with status: ${validToken.status}`);
      setIsLoggedIn(false);
      return;
    }

    const data = await validToken.json();
    if (typeof setIsLoggedIn !== 'function') {
      console.log(setIsLoggedIn);
      return;
    }

    setIsLoggedIn(data.message === 'Vous êtes bien connecté');
  } catch (error) {
    console.log('An error occurred:', error);
    setIsLoggedIn(false);
  }
}
