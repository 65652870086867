import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../App';
import { API_ROUTES } from '../data/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

function ProjectsPage() {
  const { projects, setProjects } = useContext(AuthContext);
  const [newProjectName, setNewProjectName] = useState('');
  const token = localStorage.getItem('token');

  useEffect(() => {}, []);

  const addProject = async () => {
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    try {
      const response = await fetch(`${API_ROUTES.PROJECTS}`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ projectName: newProjectName }),
      });

      if (response.ok) {
        const data = await response.json();

        setProjects((prevProjects) => [
          ...prevProjects,
          data.project || data.project,
        ]);
        setNewProjectName('');
      } else {
        console.log('Failed to add project:', await response.text());
      }
    } catch (error) {
      console.log('Error adding project:', error);
    }
  };

  const deleteProject = async (projectId) => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await fetch(`${API_ROUTES.PROJECTS}/${projectId}`, {
        method: 'DELETE',
        headers,
      });

      if (response.ok) {
        setProjects(projects.filter((p) => p._id !== projectId));
      } else {
        console.log('Failed to delete project:', await response.text());
      }
    } catch (error) {
      console.log('Error deleting project:', error);
    }
  };

  return (
    <div className="projects-page black-background">
      <h1 className="title black-text">Manage Projects</h1>

      <ul className="project-list black-list">
        {projects.map((project, index) => (
          <li
            key={index}
            className="project-item black-item"
          >
            {project.projectName}
            <button
              className="trash-button black-trash-button"
              onClick={() => deleteProject(project._id)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </li>
        ))}
      </ul>

      <div className="addProjectsDiv">
        <input
          type="text"
          className="project-input black-input"
          placeholder="New project name"
          value={newProjectName}
          onChange={(e) => setNewProjectName(e.target.value)}
        />
        <button
          className="add-button black-button"
          onClick={addProject}
        >
          Add Project
        </button>
      </div>
    </div>
  );
}

export default ProjectsPage;
