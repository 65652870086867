import React, { useContext } from 'react';
import { AuthContext } from '../App';
import ChartComponent from '../components/charts/chartcomponents';

const ChartsPage = () => {
  const { apacheLogs, apacheErrorLogs, pm2Logs, pm2ErrorLogs } =
    useContext(AuthContext);

  const allLogs = [
    { logs: apacheLogs, title: 'Apache Logs' },
    { logs: apacheErrorLogs, title: 'Apache Error Logs' },
    { logs: pm2Logs, title: 'PM2 Logs' },
    { logs: pm2ErrorLogs, title: 'PM2 Error Logs' },
  ];
  try {
    return (
      <div className="chart-page">
        <h1>Charts Page</h1>
        {allLogs.map(({ logs, title }) => (
          <ChartComponent
            key={title}
            logs={logs}
            title={title}
          />
        ))}
      </div>
    );
  } catch (error) {
    console.error('Something went wrong in ChartPage:', error);
    return <div>Error rendering chart</div>;
  }
};

export default ChartsPage;
