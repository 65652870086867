import React from 'react';
import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryTheme,
  VictoryLine,
  VictoryScatter,
  VictoryLabel,
} from 'victory';
import {
  getProjectFrequency,
  getLastUpdateTime,
  getDirectoryCount,
  getUpdateFrequency,
  getUniqueLogs,
  getProjectHeatmap,
} from './chartcalcscomponents';
import { useState } from 'react';

export default function ChartComponent({ logs, title }) {
  const projectFrequency = getProjectFrequency(logs);
  const lastUpdateTime = getLastUpdateTime(logs);
  const directoryCount = getDirectoryCount(logs);
  const updateFrequency = getUpdateFrequency(logs);
  const uniqueLogs = getUniqueLogs(logs);

  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const convertDataForChart = (data) => {
    return Object.keys(data).map((key) => ({
      x: key,
      y: data[key],
    }));
  };

  return (
    <div className="chart-container">
      <h2
        className="chart-title"
        onClick={toggleCollapse}
      >
        {' '}
        {title}{' '}
        <button className="collapse-button-chart">
          {collapsed ? '+' : '-'}
        </button>
      </h2>
      {collapsed ? null : (
        <div className="collapsed-chart-div">
          <div className="chart-div">
            {' '}
            <h3>Project Frequency</h3>
            <VictoryChart
              theme={VictoryTheme.material}
              domainPadding={20}
            >
              <VictoryAxis
                tickCount={5}
                tickLabelComponent={
                  <VictoryLabel
                    angle={45}
                    textAnchor="start"
                    verticalAnchor="middle"
                  />
                }
              />
              <VictoryAxis
                dependentAxis
                tickFormat={(x) => `${x}`}
                tickLabelComponent={
                  <VictoryLabel
                    angle={45}
                    textAnchor="start"
                    verticalAnchor="middle"
                  />
                }
              />
              <VictoryBar
                data={convertDataForChart(projectFrequency)}
                x="x"
                y="y"
                style={{ data: { fill: '#FF5733' } }}
              />
            </VictoryChart>
          </div>
          <div className="chart-div">
            <h3>Last Update Time</h3>
            <VictoryChart
              theme={VictoryTheme.material}
              domainPadding={20}
              tickLabelComponent={
                <VictoryLabel
                  angle={45}
                  textAnchor="start"
                  verticalAnchor="middle"
                />
              }
            >
              <VictoryAxis
                tickCount={5}
                tickLabelComponent={
                  <VictoryLabel
                    angle={45}
                    textAnchor="start"
                    verticalAnchor="middle"
                  />
                }
              />
              <VictoryAxis
                dependentAxis
                tickFormat={(x) => `${x}`}
                tickLabelComponent={
                  <VictoryLabel
                    angle={45}
                    textAnchor="end"
                    verticalAnchor="middle"
                  />
                }
              />
              <VictoryBar
                data={convertDataForChart(lastUpdateTime)}
                x="x"
                y="y"
                style={{ data: { fill: '#33FF57' } }}
              />
            </VictoryChart>
          </div>
          <div className="chart-div">
            <h3>Directory Count</h3>
            <VictoryChart
              theme={VictoryTheme.material}
              domainPadding={20}
            >
              <VictoryAxis
                tickCount={5}
                tickLabelComponent={
                  <VictoryLabel
                    angle={45}
                    textAnchor="start"
                    verticalAnchor="middle"
                  />
                }
              />
              <VictoryAxis
                dependentAxis
                tickFormat={(x) => `${x}`}
                tickLabelComponent={
                  <VictoryLabel
                    angle={45}
                    textAnchor="end"
                    verticalAnchor="middle"
                  />
                }
              />
              <VictoryBar
                data={convertDataForChart(directoryCount)}
                x="x"
                y="y"
                style={{ data: { fill: '#3357FF' } }}
              />
            </VictoryChart>
          </div>
          <div className="chart-div">
            <h3>Update Frequency</h3>
            <VictoryChart theme={VictoryTheme.material}>
              <VictoryAxis
                tickCount={5}
                tickLabelComponent={
                  <VictoryLabel
                    angle={45}
                    textAnchor="start"
                    verticalAnchor="middle"
                  />
                }
              />
              <VictoryAxis
                dependentAxis
                tickFormat={(x) => `${x}`}
                tickLabelComponent={
                  <VictoryLabel
                    angle={45}
                    textAnchor="end"
                    verticalAnchor="middle"
                  />
                }
              />
              <VictoryLine
                data={convertDataForChart(updateFrequency)}
                x="x"
                y="y"
                style={{ data: { stroke: '#FF33F6' } }}
              />
            </VictoryChart>
          </div>
          <div className="chart-div">
            <h3>Unique Logs</h3>
            <VictoryChart theme={VictoryTheme.material}>
              <VictoryAxis
                tickCount={5}
                tickLabelComponent={
                  <VictoryLabel
                    angle={45}
                    textAnchor="start"
                    verticalAnchor="middle"
                  />
                }
              />
              <VictoryAxis
                dependentAxis
                tickFormat={(x) => `${x}`}
                tickLabelComponent={
                  <VictoryLabel
                    angle={45}
                    textAnchor="end"
                    verticalAnchor="middle"
                  />
                }
              />
              <VictoryScatter
                data={convertDataForChart(uniqueLogs)}
                x="x"
                y="y"
                size={5}
                style={{ data: { fill: '#F6FF33' } }}
              />
            </VictoryChart>
          </div>

          <div className="chart-div">
            <h3>Project Heatmap</h3>
            <VictoryChart theme={VictoryTheme.material}>
              <VictoryAxis
                tickCount={5}
                tickLabelComponent={
                  <VictoryLabel
                    angle={45}
                    textAnchor="start"
                    verticalAnchor="middle"
                  />
                }
              />
              <VictoryAxis
                dependentAxis
                tickFormat={(x) => `${x}`}
                tickLabelComponent={
                  <VictoryLabel
                    angle={45}
                    textAnchor="end"
                    verticalAnchor="middle"
                  />
                }
              />
              <VictoryScatter
                style={{ data: { fill: '#c43a31' } }}
                bubbleProperty="amount"
                maxBubbleSize={25}
                minBubbleSize={5}
                data={getProjectHeatmap(logs)}
              />
            </VictoryChart>
          </div>
        </div>
      )}
    </div>
  );
}
