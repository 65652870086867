import { API_ROUTES } from '../data/constants';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export default function LogDetailsPage() {
  const [logDetails, setLogDetails] = useState(null);
  const token = localStorage.getItem('token');
  const { logId, logType } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_ROUTES.API}/${logType}/${logId}/content`,
          {
            headers: {
              Authorization: `Bearer: ${token}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setLogDetails(data);
        } else {
          // Handle error
          console.error('Failed to fetch data');
        }
      } catch (error) {
        // Handle error
        console.error('An error occurred:', error);
      }
    };

    fetchData();
  }, [logId, logType, token]);

  // Check if logDetails is still null
  if (logDetails === null) {
    return <div>File is being fetched for further consultation...</div>;
  }

  const ParsedLog = logDetails.parsedLogs;
  const columnTitles = Object.keys(ParsedLog[0]);

  return (
    <div>
      <h1>{logType} Log Details</h1>
      <table>
        <thead>
          <tr>
            {columnTitles.map((title, index) => (
              <th key={index}>{title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {ParsedLog.map((log, index) => (
            <tr key={index}>
              {columnTitles.map((title, i) => (
                <td key={i}>{log[title]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
