import { Link } from 'react-router-dom';

export default function HomePage() {
  return (
    <div className="home-div">
      <h1 className="home-h1">Welcome to ZefMon!</h1>
      <p className="slogan-p">
        Your one-stop solution for all your log monitoring needs.
      </p>
      <h2 className="home-h2">Why ZefMon?</h2>
      <p className="mission-statement-p">
        We help you retrieve, catalog, sort, filter, display, analyze, and
        delete logs from all of your sources. Say goodbye to chaotic logs and
        hello to actionable insights!
      </p>
      <h2 className="home-h2">Features</h2>
      <ul className="home-list">
        <li>Comprehensive Log Gathering</li>
        <li>Real-time Analysis</li>
        <li>User-friendly Dashboard</li>
        <li>Custom Filters and Alerts</li>
      </ul>
      <h2 className="home-h2">Get Started</h2>
      <p className="call-to-action-p">
        Don't let your data go to waste. Use ZefMon and turn your logs into
        actionable strategies.{' '}
        <Link
          className="call-to-action-link"
          to="/signup"
        >
          Sign up now!
        </Link>
      </p>
    </div>
  );
}
