import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFile,
  faClock,
  faFolder,
  faBriefcase,
} from '@fortawesome/free-solid-svg-icons';

export default function LogsTable({
  Logs,
  LogsUrl,
  sortKey,
  sortOrder,
  handleHeaderClick,
  checkedLogs,
  setCheckedLogs,
}) {
  const allSelected =
    Object.keys(checkedLogs).length === Logs.length &&
    !Object.values(checkedLogs).includes(false);
  const sortedLogs = sortKey
    ? [...Logs].sort((a, b) => {
        if (sortKey === 'lastUpdated') {
          return sortOrder * (new Date(b[sortKey]) - new Date(a[sortKey]));
        }
        if (a[sortKey] < b[sortKey]) return -sortOrder;
        if (a[sortKey] > b[sortKey]) return sortOrder;
        return 0;
      })
    : Logs;

  try {
    let logTableRows;

    if (Array.isArray(sortedLogs)) {
      logTableRows = sortedLogs.map((log, index) =>
        GenerateLogRow(
          log,
          index,
          LogsUrl,
          sortKey,
          sortOrder,
          checkedLogs,
          setCheckedLogs
        )
      );
    } else if (typeof sortedLogs === 'object') {
      logTableRows = Object.values(sortedLogs).map((log, index) =>
        GenerateLogRow(
          log,
          index,
          LogsUrl,
          sortKey,
          sortOrder,
          checkedLogs,
          setCheckedLogs
        )
      );
    } else {
      console.error('Logs is neither an array nor an object:', Logs);
      return null;
    }
    function selectAllToggle() {
      const allSelected =
        Object.keys(checkedLogs).length === Logs.length &&
        !Object.values(checkedLogs).includes(false);

      if (allSelected) {
        setCheckedLogs({});
      } else {
        const newCheckedLogs = {};
        Logs.forEach((log) => {
          newCheckedLogs[log._id] = true;
        });
        setCheckedLogs(newCheckedLogs);
      }
    }

    return (
      <table className="logstable">
        <thead className="logs-thead">
          <tr>
            <th className="checkbox-header">
              <label
                for="select-all"
                className="clickable-header"
              >
                <input
                  type="checkbox"
                  onClick={selectAllToggle}
                  checked={allSelected}
                  id="select-all"
                />
              </label>
            </th>
            <th
              className="clickable-header filename-header"
              onClick={() => handleHeaderClick('filename')}
            >
              <p className="desktop-header">Filename</p>
              <p className="mobile-header">
                <FontAwesomeIcon icon={faFile} />
              </p>
            </th>
            <th
              className="clickable-header lastupdate-header"
              onClick={() => handleHeaderClick('lastUpdated')}
            >
              <p className="desktop-header">Last Update</p>
              <p className="mobile-header">
                <FontAwesomeIcon icon={faClock} />
              </p>
            </th>
            <th
              className="clickable-header projectname-header"
              onClick={() => handleHeaderClick('projectName')}
            >
              <p className="desktop-header"> Project Name</p>
              <p className="mobile-header">
                <FontAwesomeIcon icon={faBriefcase} />
              </p>
            </th>
            <th
              className="clickable-header directory-header"
              onClick={() => handleHeaderClick('directory')}
            >
              <p className="desktop-header">Directory</p>
              <p className="mobile-header">
                <FontAwesomeIcon icon={faFolder} />
              </p>
            </th>
          </tr>
        </thead>
        <tbody>{logTableRows}</tbody>
      </table>
    );
  } catch (error) {
    console.error('Error in LogsTable: ', error);
    return null;
  }
}

function GenerateLogRow(
  log,
  index,
  LogsUrl,
  sortKey,
  sortOrder,
  checkedLogs,
  setCheckedLogs
) {
  const { filename, lastUpdated, projectName, directory, _id } = log;
  function HumanReadableTime(lastUpdated) {
    const now = new Date();
    const updateDate = new Date(lastUpdated);
    const diffInSeconds = Math.floor((now - updateDate) / 1000);

    if (diffInSeconds < 60) return 'Just now';
    if (diffInSeconds < 3600)
      return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    if (diffInSeconds < 21600)
      return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    if (diffInSeconds < 86400) return 'Today';
    if (diffInSeconds < 172800) return 'Yesterday';
    return `${Math.floor(diffInSeconds / 86400)} days ago`;
  }

  return (
    <tr key={index}>
      <td className="checkbox-row">
        <input
          type="checkbox"
          id={`checkbox-${_id} ${LogsUrl}/${_id}`}
          checked={!!checkedLogs[_id]}
          onChange={() => {
            setCheckedLogs((prevState) => ({
              ...prevState,
              [_id]: !prevState[_id],
            }));
          }}
        />
      </td>
      <td className="filename-row">
        <Link to={`${LogsUrl}/${_id}`}>{filename}</Link>
      </td>
      <td className="lastupdated-row">{HumanReadableTime(lastUpdated)}</td>
      <td className="projectname-row">{projectName}</td>
      <td className="directory-row">{directory}</td>
    </tr>
  );
}
