import Logo from '../../images/zeflogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faX } from '@fortawesome/free-solid-svg-icons';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../App';
import LoggedIn from '../loggedin/loggedincomponent';
import React from 'react';
export default function Footer() {
  const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
  const [menuOpen, setMenuOpen] = useState(false);
  function HandleLogout({ setIsLoggedIn }) {
    console.log('I have been summoned');
    localStorage.clear();
    LoggedIn(setIsLoggedIn);
  }

  const classFa = menuOpen ? faX : faBars;

  return (
    <React.Fragment>
      <div className="nav-div">
        <div className="mobile-nav-div">
          <button
            className="collapse-menu-btn"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <FontAwesomeIcon icon={classFa} />
          </button>
          {menuOpen ? (
            <nav className="collapse-menu-div">
              <ul className="mobile-nav-list">
                <li>
                  <Link
                    onClick={() => setMenuOpen(!menuOpen)}
                    to="/"
                  >
                    Accueil
                  </Link>
                </li>

                {isLoggedIn ? (
                  <React.Fragment>
                    <li>
                      <Link
                        onClick={() => setMenuOpen(!menuOpen)}
                        to="/logs"
                      >
                        Logs
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setMenuOpen(!menuOpen)}
                        to="/charts"
                      >
                        Charts
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => setMenuOpen(!menuOpen)}
                        to="/projects"
                      >
                        Projects
                      </Link>
                    </li>
                    <li>
                      <a
                        href="/login"
                        onClick={() => HandleLogout({ setIsLoggedIn })}
                      >
                        Logout
                      </a>
                    </li>
                  </React.Fragment>
                ) : (
                  <li>
                    <Link
                      onClick={() => setMenuOpen(!menuOpen)}
                      to="/login"
                    >
                      Login
                    </Link>
                  </li>
                )}
              </ul>
            </nav>
          ) : (
            ''
          )}
        </div>
      </div>
      <footer className="footer-div">
        <img
          src={Logo}
          alt="ZefMon Logo"
          className="zefmon-logo-footer"
        ></img>
      </footer>
    </React.Fragment>
  );
}
