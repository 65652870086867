import { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../App';
import { API_ROUTES } from '../data/constants';
import LogsTable from '../components/logstables/logstablescomponent';

export default function LogsPage() {
  const [logDataArray, setLogDataArray] = useState([]);
  const { isLoggedIn, apacheLogs, apacheErrorLogs, pm2Logs, pm2ErrorLogs } =
    useContext(AuthContext);
  const [sortKey, setSortKey] = useState(null);
  const [sortOrder, setSortOrder] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [checkedLogs, setCheckedLogs] = useState({});
  const [filterChecked, setFilterChecked] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const { projects } = useContext(AuthContext);
  const [selectedProjectId, setSelectedProjectId] = useState();
  const [collapsedSections, setCollapsedSections] = useState({});
  const [pageNumbers, setPageNumbers] = useState({});
  const [entryLimit, setEntryLimit] = useState(10);
  const [entryIndices, setEntryIndices] = useState({});

  const ToggleSectionCollapse = (label) => {
    setCollapsedSections({
      ...collapsedSections,
      [label]: !collapsedSections[label],
    });
  };

  const token = localStorage.getItem('token');
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  };

  const selectedLogIds = Object.keys(checkedLogs).filter(
    (id) => checkedLogs[id]
  );

  const deleteLogs = async () => {
    let deletedLogs = [];
    for (const logId of selectedLogIds) {
      const logData = logDataArray.find((data) =>
        data.data.some((log) => log._id === logId)
      );
      const LogsUrl = logData?.url || '';
      const response = await fetch(`${API_ROUTES.API}/${LogsUrl}/${logId}`, {
        method: 'DELETE',
        headers,
      });
      if (response.ok) {
        deletedLogs.push(logId);
      }

      if (!response.ok) {
        console.log('Failed to delete log', await response.text());
      }
      setLogDataArray(
        logDataArray.map((logData) => ({
          ...logData,
          data: logData.data.filter((log) => !deletedLogs.includes(log._id)),
        }))
      );
    }
  };

  const updateLogs = async () => {
    let updatedLogs = [];
    for (const logId of selectedLogIds) {
      const logData = logDataArray.find((data) =>
        data.data.some((log) => log._id === logId)
      );
      const LogsUrl = logData?.url || '';
      const response = await fetch(`${API_ROUTES.API}/${LogsUrl}/${logId}`, {
        method: 'PUT',
        headers,
        body: JSON.stringify({ projectName: selectedProjectId }),
      });
      if (response.ok) {
        updatedLogs.push(logId);
      }

      if (!response.ok) {
        console.log('Failed to update log', await response.text());
      }

      setLogDataArray(
        logDataArray.map((logData) => ({
          ...logData,
          data: logData.data.map((log) =>
            updatedLogs.includes(log._id)
              ? { ...log, projectName: selectedProjectId }
              : log
          ),
        }))
      );
    }
  };

  const handleUpdateClick = () => {
    updateLogs();
    setModalOpen(false); // Close the modal after the update
  };

  useEffect(() => {
    if (projects.length > 0) {
      setSelectedProjectId(projects[0].projectName);
    }

    setLogDataArray([
      { label: 'Apache Logs', url: 'apache2logs', data: apacheLogs },
      {
        label: 'Apache Error Logs',
        url: 'apache2errorlogs',
        data: apacheErrorLogs,
      },
      { label: 'PM2 Logs', url: 'pm2logs', data: pm2Logs },
      { label: 'PM2 Error Logs', url: 'pm2errorlogs', data: pm2ErrorLogs },
    ]);
  }, [apacheLogs, apacheErrorLogs, pm2Logs, pm2ErrorLogs, projects]);
  const handleHeaderClick = (newSortKey) => {
    if (sortKey === newSortKey) {
      setSortOrder(-sortOrder); // Toggle sort order
    } else {
      setSortKey(newSortKey);
      setSortOrder(1); // Reset to ascending when we change the sort key
    }
  };
  const filteredLogDataArray = logDataArray.map((logData) => ({
    ...logData,
    data: logData.data
      .filter((log) =>
        Object.values(log).some((value) =>
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      )
      .sort((a, b) => {
        if (sortKey && a.hasOwnProperty(sortKey) && b.hasOwnProperty(sortKey)) {
          return (a[sortKey] > b[sortKey] ? 1 : -1) * sortOrder;
        }
        return 0;
      }),
  }));

  const UpdatePage = (label, pageNumber) => {
    const newPageNumber = pageNumber > 0 ? pageNumber : 1;
    setPageNumbers({
      ...pageNumbers,
      [label]: newPageNumber,
    });
    setEntryIndices({
      ...entryIndices,
      [label]: (newPageNumber - 1) * entryLimit,
    });
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="searchBar"
      />
      <button
        className="stylish-button"
        onClick={() => setFilterChecked(!filterChecked)}
      >
        {filterChecked ? 'Show All' : 'Show Checked Only'}
      </button>
      <button
        className="stylish-button"
        onClick={() => deleteLogs(filterChecked)}
      >
        Delete Selected Logs
      </button>
      <button
        className="stylish-button"
        onClick={() => setModalOpen(true)}
      >
        Update Selected Logs
      </button>
      <select
        className="stylish-button"
        onChange={(e) => setEntryLimit(Number(e.target.value))}
      >
        <option value={10}>10</option>
        <option value={20}>20</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
      </select>

      {isLoggedIn ? (
        <div className="LoggedInLogsDiv">
          {filteredLogDataArray.map((logData, index) => {
            const maxPage = Math.ceil(logData.data.length / entryLimit);
            if (pageNumbers[logData.label] > maxPage) {
              UpdatePage(logData.label, (pageNumbers[logData.label] || 1) - 1);
            }
            return (
              <div
                className="LogDiv"
                key={index}
              >
                <h3>
                  {logData.label}
                  <button
                    className="collapse-button"
                    onClick={() => ToggleSectionCollapse(logData.label)}
                  >
                    {collapsedSections[logData.label] ? '+' : '-'}
                  </button>
                </h3>
                <div
                  className={`collapsible-content ${
                    collapsedSections[logData.label] ? 'collapsed' : ''
                  }`}
                >
                  {!collapsedSections[logData.label] && (
                    <>
                      <LogsTable
                        Logs={
                          filterChecked
                            ? logData.data
                                .filter((log) => checkedLogs[log._id])
                                .slice(
                                  entryIndices[logData.label] || 0,
                                  (entryIndices[logData.label] || 0) +
                                    entryLimit
                                )
                            : logData.data.slice(
                                entryIndices[logData.label] || 0,
                                (entryIndices[logData.label] || 0) + entryLimit
                              )
                        }
                        LogsUrl={logData.url}
                        sortKey={sortKey}
                        sortOrder={sortOrder}
                        handleHeaderClick={handleHeaderClick}
                        checkedLogs={checkedLogs}
                        setCheckedLogs={setCheckedLogs}
                      />{' '}
                      <div className="pagination">
                        <button
                          className="stylish-button"
                          disabled={pageNumbers[logData.label] <= 1}
                          onClick={() =>
                            UpdatePage(
                              logData.label,
                              (pageNumbers[logData.label] || 1) - 1
                            )
                          }
                        >
                          Previous
                        </button>

                        <span>
                          {pageNumbers[logData.label] || 1} / {maxPage}
                        </span>
                        <button
                          className="stylish-button"
                          disabled={
                            (pageNumbers[logData.label] || 1) >= maxPage
                          }
                          onClick={() =>
                            UpdatePage(
                              logData.label,
                              (pageNumbers[logData.label] || 1) + 1
                            )
                          }
                        >
                          Next
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div>Connectez-vous pour accéder aux logs</div>
      )}

      <button
        className="stylish-button"
        onClick={() => setFilterChecked(!filterChecked)}
      >
        {filterChecked ? 'Show All' : 'Show Checked Only'}
      </button>
      <button
        className="stylish-button"
        onClick={() => deleteLogs(filterChecked)}
      >
        Delete Selected Logs
      </button>
      <button
        className="stylish-button"
        onClick={() => setModalOpen(true)}
      >
        Update Selected Logs
      </button>
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <button
              className="close-button"
              onClick={() => setModalOpen(false)}
            >
              &times;
            </button>
            <div className="modal-header">
              <h2>Select a project to update logs</h2>
            </div>
            <div className="modal-body">
              <select onChange={(e) => setSelectedProjectId(e.target.value)}>
                {projects.map((project, index) => (
                  <option
                    key={index}
                    value={project.projectName}
                  >
                    {project.projectName}
                  </option>
                ))}
              </select>
            </div>
            <div className="modal-footer">
              <button onClick={handleUpdateClick}>Update</button>
              <button onClick={() => setModalOpen(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
