import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../App';
import LoggedIn from '../loggedin/loggedincomponent';
import React from 'react';

export default function Banner() {
  const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);

  function HandleLogout({ setIsLoggedIn }) {
    console.log('I have been summoned');
    localStorage.clear();
    LoggedIn(setIsLoggedIn);
  }

  return (
    <div className="nav-div">
      <nav className="desktop-nav-div">
        <ul className="desktop-nav-list">
          <li>
            <Link to="/">Accueil</Link>
          </li>

          {isLoggedIn ? (
            <React.Fragment>
              <li>
                <Link to="/logs">Logs</Link>
              </li>
              <li>
                <Link to="/charts">Charts</Link>
              </li>
              <li>
                <Link to="/projects">Projects</Link>
              </li>
              <li>
                <a
                  href="/login"
                  onClick={() => HandleLogout({ setIsLoggedIn })}
                >
                  Logout
                </a>
              </li>
            </React.Fragment>
          ) : (
            <li>
              <Link to="/login">Login</Link>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
}
