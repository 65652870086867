const localUrl = '/api';
export const wsUrl = 'wss://zefmon.arthurpellissier.com/wss';
export const API_ROUTES = {
  API: `${localUrl}`,
  LOGIN: `${localUrl}/admin/login`,
  IDCHECK: `${localUrl}/admin/check`,
  GET_APACHELOGS: `${localUrl}/apache2logs`,
  GET_APACHEERRORLOGS: `${localUrl}/apache2errorlogs`,
  GET_PM2LOGS: `${localUrl}/pm2logs`,
  GET_PM2ERRORLOGS: `${localUrl}/pm2errorlogs`,
  PROJECTS: `${localUrl}/projects`,
};
export const FILES_URL = '/files';
