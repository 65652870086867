// Function to calculate project frequency
export const getProjectFrequency = (logs) => {
  const projectFrequency = {};
  logs.forEach((log) => {
    const project = log.projectName;
    projectFrequency[project] = (projectFrequency[project] || 0) + 1;
  });
  console.log('Project Frequency : ', projectFrequency);
  return projectFrequency;
};

// Function to calculate the last update time for each project
export const getLastUpdateTime = (logs) => {
  const lastUpdateTime = {};
  logs.forEach((log) => {
    const project = log.projectName;
    const time = new Date(log.lastUpdated);
    const categorizedTime = categorizeUpdateTime(time); // Categorize the date
    if (!lastUpdateTime[project] || lastUpdateTime[project] < time) {
      lastUpdateTime[project] = categorizedTime;
    }
  });
  console.log('last update time : ', lastUpdateTime);
  return lastUpdateTime;
};

// Function to count directory frequency
export const getDirectoryCount = (logs) => {
  const directoryCount = {};
  logs.forEach((log) => {
    const dir = log.directory;
    directoryCount[dir] = (directoryCount[dir] || 0) + 1;
  });
  console.log('directory count : ', directoryCount);
  return directoryCount;
};

// Function to count update frequency by date
export const getUpdateFrequency = (logs) => {
  const updateFrequency = {};
  logs.forEach((log) => {
    const date = new Date(log.lastUpdated).toDateString();
    updateFrequency[date] = (updateFrequency[date] || 0) + 1;
  });
  console.log('update Frequency', updateFrequency);
  return updateFrequency;
};

// Function to count unique logs per project
export const getUniqueLogs = (logs) => {
  const uniqueLogs = {};
  logs.forEach((log) => {
    const project = log.projectName;
    uniqueLogs[project] = new Set(uniqueLogs[project] || []);
    uniqueLogs[project].add(log.filename);
  });
  Object.keys(uniqueLogs).forEach((key) => {
    uniqueLogs[key] = uniqueLogs[key].size;
  });
  console.log('unique logs : ', uniqueLogs);
  return uniqueLogs;
};

// Function to count frequent updates by filename
export const getFrequentUpdates = (logs) => {
  const frequentUpdates = {};
  logs.forEach((log) => {
    const file = log.filename;
    frequentUpdates[file] = (frequentUpdates[file] || 0) + 1;
  });
  console.log('frequent updates : ', frequentUpdates);
  return frequentUpdates;
};

// Function to build project heatmap data
export const getProjectHeatmap = (logs) => {
  const projectHeatmap = {};

  logs.forEach((log) => {
    const project = log.projectName;
    const category = categorizeUpdateTime(log.lastUpdated);

    projectHeatmap[project] = projectHeatmap[project] || {};
    projectHeatmap[project][category] =
      (projectHeatmap[project][category] || 0) + 1;
  });

  const flattenedHeatmap = [];

  Object.keys(projectHeatmap).forEach((project) => {
    Object.keys(projectHeatmap[project]).forEach((category) => {
      flattenedHeatmap.push({
        x: project,
        y: category,
        amount: projectHeatmap[project][category],
      });
    });
  });

  console.log('Project Heatmap', flattenedHeatmap);
  return flattenedHeatmap;
};

// Function to categorize update time
export const categorizeUpdateTime = (lastUpdate) => {
  const now = new Date();
  const updateTime = new Date(lastUpdate);
  const diffInHours = (now - updateTime) / 1000 / 60 / 60;
  if (diffInHours < 1) return 'Last hour';
  if (diffInHours < 24) return 'Last 24 hours';
  if (diffInHours < 168) return 'Last week';
  return 'Older';
};
