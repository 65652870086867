import React from 'react';
import { API_ROUTES } from '../../data/constants';
import { useNavigate } from 'react-router-dom';

import LoggedIn from '../loggedin/loggedincomponent';

export default function LoginForm({ setIsLoggedIn }) {
  const navigate = useNavigate();

  const handleLogin = async (username, password) => {
    const adminLoginAttempt = await fetch(`${API_ROUTES.LOGIN}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    });

    if (!adminLoginAttempt.ok) {
      const errorData = await adminLoginAttempt.text();
      throw new Error(`Something went wrong: ${errorData}`);
    }

    const data = await adminLoginAttempt.json();

    localStorage.setItem('adminId', data.adminId);
    localStorage.setItem('token', data.token);

    navigate('/logs');
    LoggedIn(setIsLoggedIn);
  };

  return (
    <main className="loginMain">
      <section className="adminForm">
        <h1 className="formTitle">Connexion Admin</h1>
        <CreateContactForm handleLogin={handleLogin} />
      </section>
    </main>
  );
}

function CreateContactForm({ handleLogin }) {
  const handleSubmit = async (event) => {
    event.preventDefault();
    const username = event.target.username.value;
    const password = event.target.password.value;

    handleLogin(username, password);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="loginForm"
    >
      <label
        htmlFor="username"
        className="formLabel"
      >
        <h2>Username</h2>
        <input
          className="input"
          type="input"
          id="username"
          name="username"
          required={true}
        />
      </label>
      <label
        htmlFor="password"
        className="formLabel"
      >
        <h2>Password</h2>
        <input
          className="input"
          type="password"
          id="password"
          name="password"
          required={true}
        />
      </label>

      <button
        type="submit"
        className="submit-button"
      >
        Envoyer
      </button>
    </form>
  );
}
